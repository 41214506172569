import { useState } from 'react';
import { ApolloError, gql, useLazyQuery, useQuery } from '@apollo/client';
import { Dropdown, Col, Card } from 'react-bootstrap'
import 'chart.js/auto';
import { Doughnut, Line } from 'react-chartjs-2'
import 'chartjs-adapter-dayjs'

import './Dashboard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { TimePast } from './lib/models';

const GET_STATS = gql`
    query GetStats($time: TimePast!) {
        stats(time_past: $time) {
            totalClicks
            totalViews
        }
    }
`;

const GET_GRAPHS = gql`
    query GetGraphs($time: TimePast!) {
        stats(time_past: $time) {
            clicksData {
                value
                dataTime
            }
            viewsData {
                value
                dataTime
            }
        }
    }

`

export function MetricCard({ title, children }: { title: string, children: React.ReactElement }) {
    return (
        <Card className="metrics-card">
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                {children}
                <p className="text-center mb-0">{title}</p>
            </Card.Body>
        </Card>
    )
}

function DashboardPage() {
    const [time, setTime] = useState(TimePast.DAY);
    const { loading, error, data } = useQuery(GET_STATS, {
        variables: { time }
    });

    let PopupsShownComponent = ({ time }: { time: TimePast }) => {
        if (loading) return <FontAwesomeIcon icon={faSpinnerThird} spin />;
        if (error) return <div> {error.message}</div>;

        return <h1>{data.stats.totalViews}</h1>
    }

    let PopupsClickedComponent = ({ time }: { time: TimePast }) => {
        if (loading) return <FontAwesomeIcon icon={faSpinnerThird} spin />;
        if (error) return <div>Error :( </div>;

        return <h1>{data.stats.totalClicks}</h1>
    }

    let ConversionComponent = ({ time }: { time: TimePast }) => {
        if (loading) return <FontAwesomeIcon icon={faSpinnerThird} spin />;
        if (error) return <div>Error :( </div>;

        let pct = (data.stats.totalClicks / data.stats.totalViews) * 100
        return <h1>{pct.toFixed(1)}%</h1>
    }

    let ViewGraph = ({ time }: { time: TimePast }) => {
        const { loading, error, data } = useQuery(GET_GRAPHS, {
            variables: { time }
        });

        if (loading) return <FontAwesomeIcon icon={faSpinnerThird} spin />;
        if (error) return <div>Error :( </div>;

        return (
            <Line
                data={{
                    labels: data.stats.viewsData.map((d: any) => d.dataTime),
                    datasets: [{
                        label: 'Clicks',
                        data: data.stats.clicksData.map((d: any) => d.value),
                        backgroundColor: "red",
                        borderColor: "red",
                    }, {
                        label: 'Views',
                        data: data.stats.viewsData.map((d: any) => d.value),
                        backgroundColor: "blue",
                        borderColor: "blue",
                    }]
                }}
                options={{
                    scales: {
                        xAxis: {
                            // The axis for this scale is determined from the first letter of the id as `'x'`
                            // It is recommended to specify `position` and / or `axis` explicitly.
                            type: 'time',
                            time: {
                                unit: 'day'
                            }
                        }
                    }
                }}
            />
        )
    }

    let DonutGraph = ({ time }: { time: TimePast }) => {
        if (loading) return <FontAwesomeIcon icon={faSpinnerThird} spin />;
        if (error) return <div>Error :( </div>;

        if (data.stats.totalViews === 0) {
            return <div> No Data Yet! </div>
        }

        return (
            <Doughnut data={{
                labels: ['Clicked', 'Shown'],
                datasets: [{
                    label: 'Clicks',
                    data: [data.stats.totalClicks, data.stats.totalViews],
                    backgroundColor: ["red", "blue"]
                }]
            }} />
        )
    }

    let Donut = () => {
        return <h1>789</h1>
    }

    let PopupClickGraphComponent = () => {
        return <h1>101112</h1>
    }

    let DropdownComponent = () => {
        let timeText = {
            "DAY": "Today",
            "WEEK": "Last 7 Days",
            "MONTH": "This Month",
        }

        return (
            <Dropdown className="ms-auto">
                <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                    {timeText[time]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setTime(TimePast.DAY)}>
                        Today
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setTime(TimePast.WEEK)}>
                        Last 7 Days
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setTime(TimePast.MONTH)}>
                        This Month
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <>
            <div className="d-flex">
                <h2> Dashboard</h2>
                <DropdownComponent />
            </div>
            <div className="metric-card-collection w-100">
                <MetricCard title="Popups Shown">
                    <PopupsShownComponent time={time} />
                </MetricCard>
                <MetricCard title="Popups Clicked">
                    <PopupsClickedComponent time={time} />
                </MetricCard>
                <MetricCard title="Conversion">
                    <ConversionComponent time={time} />
                </MetricCard>
            </div>

            <div className="d-flex mt-3 metric-card-collection h-100">
                <Card className="metrics-card graph-card">
                    <Card.Body>
                        <h4 className="mb-3"> History </h4>
                        <ViewGraph time={time} />
                    </Card.Body>
                </Card>
                <Card className="metrics-card flex-1">
                    <Card.Body>
                        <h4 className="mb-3"> View Stats </h4>
                        <DonutGraph time={time} />
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default DashboardPage