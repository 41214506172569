import './Login.css';

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import { Form, Card, FloatingLabel } from 'react-bootstrap'
import { JWTContext } from '../Contexts'
import {
    RouteComponentProps, useHistory, useLocation,
} from "react-router-dom";
import qs from 'qs'
import logo from '../logo.svg'

import { gql, useMutation, OperationVariables, FetchResult, ApolloError } from '@apollo/client'
import { capitalize } from '../lib/utils'

type State = {
    email: string,
    password: string,
    confirmPassword: string,
    name: string
}

type Props = RouteComponentProps & {
    query: any,
    result: OperationVariables
}

const SIGNUP_QUERY = gql`
    mutation Signup($email: String!, $name: String! $password: String!, $confirmPassword: String!) {
        createUser(input: {
            email: $email,
            name: $name,
            password: $password,
            confirmPassword: $confirmPassword
        }) {
            token
        }
    }
`;

const Signup = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [name, setName] = useState("")
    const [
        signupQuery,
        result
    ] = useMutation(SIGNUP_QUERY);
    const location = useLocation()
    const history = useHistory()


    const handleSubmitButtonClick = (e: React.FormEvent) => {
        e.preventDefault()

        const next = qs.parse(location.search, { ignoreQueryPrefix: true }).next?.toString()

        signupQuery({
            variables: {
                email,
                password,
                confirmPassword,
                name
            }
        }).then((res: FetchResult) => {
            console.log("Result", res)
            // this.context.set_jwt(res.token)
            history.push(
                next ? "/login?account_created=t&next=" + next : "/login?account_created=t"
            )
        }).catch((err: ApolloError) => {
            console.warn("Error logging in", err.message)
        })
    }


    const create_form = (
        <Form.Group className="w-100">
            <form onSubmit={handleSubmitButtonClick}>
                <FloatingLabel
                    label="Email"
                    controlId="email"
                >
                    <Form.Control
                        placeholder="richard@piedpiper.com"
                        type="email"
                        className="loginInput mb-3"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FloatingLabel>
                <FloatingLabel
                    label="Company Name"
                    controlId="name"
                >
                    <Form.Control
                        placeholder="Pied Piper"
                        type="text"
                        className="loginInput mb-3"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </FloatingLabel>
                <FloatingLabel
                    label="Password"
                    controlId="password"
                >
                    <Form.Control
                        placeholder="Password"
                        type="password"
                        className="loginInput mb-3"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FloatingLabel>
                <FloatingLabel
                    label="Confirm Password"
                    controlId="confpwd"
                >

                    <Form.Control
                        placeholder="Confirm Password"
                        type="password"
                        className="loginInput mb-3"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </FloatingLabel>

                {result.error ?
                    <div className="text-danger mb-3"> {capitalize(result.error.message)} </div> :
                    <></>
                }
                <Button className="w-100" type="submit">
                    {!result.loading ?
                        <> Create Account </>
                        : <> Loading </>
                    }
                </Button>
            </form>
        </Form.Group>
    )

    return <>
        <div className="signup-region full-height">
            <div className="login-bk-top" />
            <div className="login-bk-bot" />
            <Card className={"shadow-card login-card " + (result.error ? "error-card" : "")}>
                <Card.Body className="pb-4 pl-4 pr-4 pt-4">
                    <img src={logo} className="login-image" alt={"Shiftsy Logo"} />

                    <h4 className="mt-3 mb-3"> Create a Shiftsy Account </h4>
                    <p>
                        Shiftsy currently only works with the Shopify App on the Shopify store.
                        General purpose scripting options are coming soon!
                    </p>
                    {/* {create_form} */}
                    {/* <hr className="w-100" /> */}
                    <Button variant="outline-success" onClick={() =>
                        window.location.href = "https://apps.shopify.com/monoid-2"
                    }>
                        Sign up with Shopify
                    </Button>
                </Card.Body>
            </Card>
        </div>
    </>
}

export default Signup;