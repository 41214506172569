import { Card, Button, ProgressBar } from 'react-bootstrap'
import './Pricing.css'
import { gql, useMutation, useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { TimePast } from './lib/models';

const SELECT_PLAN = gql`
    mutation SelectPlan($planName: String!) {
        selectPlan(planName: $planName) {
            confirmationURL
        }
    }
`;

const CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      plan
    }
  }
`;

const GET_STATS = gql`
    query GetStats($time: TimePast!) {
        stats(time_past: $time) {
            totalViews
        }
    }
`;

const planStats: { [plan: string]: number } = {
    free: 500,
    starter: 10000,
    pro: 100000,
}

function PricingBar() {
    const { data, loading, error } = useQuery(GET_STATS, { variables: { time: TimePast.MONTH } })
    const userRes = useQuery(CURRENT_USER)
    let cardBody = <></>

    if (userRes.loading) {
        return (
            <Card className="mb-3">
                <Card.Body>
                    <h4 className="mb-3"> Usage </h4>

                    <FontAwesomeIcon icon={faSpinnerThird} spin />
                </Card.Body>
            </Card>
        )
    }

    let currPlan = userRes.data.currentUser.plan

    if (loading) {
        return (
            <Card className="mb-3">
                <Card.Body>
                    <h4 className="mb-3"> Usage </h4>

                    <FontAwesomeIcon icon={faSpinnerThird} spin />
                </Card.Body>
            </Card>
        )
    }

    return (
        <Card className="mb-3">
            <Card.Body>
                <h4 className="mb-3"> Usage </h4>
                <ProgressBar now={100 * data.stats.totalViews / (planStats[currPlan] || 500)} />
                <p className="mb-0 mt-3"> {data.stats.totalViews} popups shown of {planStats[currPlan]} </p>
            </Card.Body>
        </Card>
    )
}

function PricingCard(props: {
    planTitle: string,
    planCost: number,
    planID: string,
    planBenefits: string[]
}) {
    const [selectPlan, result] = useMutation(SELECT_PLAN)
    const userRes = useQuery(CURRENT_USER)

    if (userRes.loading) {
        return <FontAwesomeIcon icon={faSpinnerThird} spin />
    }

    let currPlan = userRes.data.currentUser.plan

    return (
        <Card className="pricing-card">
            <Card.Body className="d-flex flex-column">
                <div className="plan-title"> {props.planTitle} </div>
                <div className="plan-cost-region">
                    <span> ${props.planCost} </span>
                    <span> /month </span>
                </div>
                <ul className="mt-3">
                    {props.planBenefits.map((b) => (
                        <li> {b} </li>
                    ))}
                </ul>
                <Button className="mt-auto"
                    onClick={() => {
                        selectPlan({
                            variables: {
                                planName: props.planID,
                            }
                        }).then((res) => {
                            if (!res.data) {
                                return
                            }

                            window.location.href = res.data.selectPlan.confirmationURL
                        })
                    }}
                    disabled={result.loading || currPlan == props.planID}
                    variant={currPlan === props.planID ? "success" : "primary"}
                >
                    {result.loading ?
                        <FontAwesomeIcon icon={faSpinnerThird} spin />
                        : (currPlan === props.planID ? "Current Plan" : "Select Plan")
                    }
                </Button>
            </Card.Body>
        </Card>
    )
}

function Pricing() {

    return (
        <>
            <div className="billing-container">
                <h2 className="mb-3"> Billing </h2>
                <PricingBar />
                <div className="d-flex">
                    <PricingCard
                        planTitle="Free"
                        planCost={0}
                        planID="free"
                        planBenefits={[
                            "Up to 500 popups shown per month",
                        ]}
                    />
                    <PricingCard
                        planTitle="Starter"
                        planCost={19}
                        planID="starter"
                        planBenefits={[
                            "7-day free trial",
                            "Up to 10,000 popups shown per month",
                            "Popup Analytics",
                            "Same-day chat support"
                        ]}
                    />
                    <PricingCard
                        planTitle="Professional"
                        planCost={79}
                        planID="pro"
                        planBenefits={[
                            "7-day free trial",
                            "Up to 100,000 popups shown per month",
                            "Popup Analytics",
                            "Same-day support"

                        ]}
                    />
                </div>
            </div>
        </>

    )

}

export default Pricing