export interface User {
    id: number;
    email: string;
    companyName: string, 
    onboarded: boolean
}

export enum SettingsLocation {
    BOTTOM_LEFT = "BOTTOM_LEFT",
    BOTTOM_RIGHT = "BOTTOM_RIGHT",
    TOP_LEFT = "TOP_LEFT",
    TOP_RIGHT = "TOP_RIGHT"
}

export type Settings = {
    addToCart: boolean,
    purchase: boolean,
    location: SettingsLocation,
    amount: number,
    delay: number,
    enabled: boolean
}

export enum TimePast {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH"
}