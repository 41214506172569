import logo from './logo.svg';
import './App.css';
import { ProSidebar, SidebarHeader, Menu, MenuItem } from 'react-pro-sidebar';
import React, { useEffect, useState } from 'react';
import './custom.scss';
import {
    BrowserRouter as Router,
    Link,
    Route,
    Redirect,
    useLocation,
} from "react-router-dom";
import { User } from './lib/models'
// Auth imports
import Login from './auth/Login';
import Signup from './auth/Signup';
import { JWTContext } from './Contexts';
import OauthLanding from './auth/OauthLanding';
import { Container, Row, Col, Button, Modal, Card } from 'react-bootstrap';
import { ApolloError, gql, useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird, faChartPie, faCog, faCreditCard } from '@fortawesome/pro-light-svg-icons';
import Pricing from './Pricing';
import DashboardPage from './Dashboard'
import SettingsPage from './Settings'
import {ChatWidget} from "@papercups-io/chat-widget";

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      id
      email
      companyName
      onboarded
    }
  }
`;

const UPDATE_ONBOARDING = gql`
    mutation UpdateOnboarding($onboarded: Boolean!) {
        updateOnboarded(onboarded: $onboarded) 
    }
`;

function AppSidebar() {
    const location = useLocation();

    return (
        <ProSidebar className="shiftsy-sidebar">
            <SidebarHeader>
                <img src={logo} className="sidebar-logo" />
                <div className="sidebar-text">
                    Shiftsy
                </div>
            </SidebarHeader>
            <Menu>
                <MenuItem icon={<FontAwesomeIcon icon={faChartPie} />} active={
                    location.pathname == "/dashboard"
                }>
                    <Link to="/dashboard">
                        Dashboard
                    </Link>
                </MenuItem>
                <MenuItem icon={<FontAwesomeIcon icon={faCog} />} active={
                    location.pathname == "/settings"
                }>
                    <Link to="/settings">Settings</Link>
                </MenuItem>
                <MenuItem icon={<FontAwesomeIcon icon={faCreditCard} />} active={
                    location.pathname == "/account"
                }>
                    <Link to="/account"> Billing</Link>
                </MenuItem>
            </Menu>
        </ProSidebar>
    )
}

function App() {
    const [token, setToken] = useState('')
    const [user, setUser] = useState<User | undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const setGlobalToken = (jwt_token: string) => {
        localStorage.setItem('token', jwt_token);
        setToken(jwt_token)
        getCurrentUser()
    }

    const [getCurrentUser, { refetch }] = useLazyQuery(GET_CURRENT_USER, {
        onCompleted: (data: any) => {
            setUser(data.currentUser);
            setLoading(false)
        }, onError: (error: ApolloError) => {
            console.log(error);
            localStorage.removeItem('token')

            setLoading(false)
        }
    })

    interface OnboardingSequenceProps {
        onboarded: boolean,
    }
    

    let OnboardingSequence = (props: OnboardingSequenceProps) => {
        let [step, setStep] = useState(0)
        let [hide, setHide] = useState(props.onboarded)
        const [onboardingMutation, { data: data_onboarding, loading: loading_onboarding, error: error_onboarding }] = useMutation(UPDATE_ONBOARDING)
        let titles = ['Welcome to Shiftsy!', 'Dashboard', 'Settings', "That's it!"]
        let bodies = [
            "We're excited to have you onboard! In this walkthrough, we'll get you started with social proof pop-ups in under a minute.",
            "This is the Shiftsy Dashboard. Here, you can view metrics like the number of popups seen and clicked.",
            "This is the Shiftsy settings page. Here, you can customize your popup's location, triggers, frequency, and more. You can also enable and disable Shiftsy on your store - to get started, enable Shiftsy by toggling the 'Visibility' attribute on this page.",
            "Thanks for using Shiftsy! If you have any questions, please use the chat bubble in the botton-right corner of the screen, or email us at j@shiftsy.co."
        ]

        let buttons = [
            <Button onClick={()=>{setStep(1)}}>
                Next
            </Button>,
            <Link to='/settings'>
            <Button onClick={()=>{setStep(2)}}>
                Next
            </Button>
            </Link>,
            <Button onClick={()=>{setStep(3)}}>
                Next
            </Button>, 
            <Button onClick={()=>{
                onboardingMutation({variables: { onboarded: true }});
                setHide(true)
                }}>
                Done
            </Button>
        ]

        return <> { hide ? <></> : <>
            <Card 
            style={{position: 'fixed', bottom: '2em', left: '2em',boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)", zIndex: '10100', maxWidth: '400px'}}
            >
                <Modal.Header>
                    <Modal.Title>{titles[step]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {bodies[step]}
                </Modal.Body>
                <Modal.Footer>
                    {buttons[step]}
                </Modal.Footer>
            </Card>
            </> } </>

    }

    useEffect(() => {
        const tok = localStorage.getItem('token')
        console.log("hmmm", tok)

        if (tok && tok.trim() !== "") {
            console.log("set token")
            setGlobalToken(tok.trim())
        } else {
            setLoading(false)
        }
    }, [getCurrentUser])


    console.log("User", user)


    if (loading) {
        return <FontAwesomeIcon icon={faSpinnerThird} spin />
    }

    return (
        <div className="app-styling">
            <JWTContext.Provider
                value={{
                    user: user, jwt: token, setJwt: setGlobalToken, updateUser: () => {
                        if (refetch) {
                            refetch().then(res => {
                                setUser(res.data.currentUser);
                            })
                        }
                    }
                }}>
                <Router>
                    {!user ?
                        <>
                            <Route path="/" exact>
                                <Redirect to="/login" />
                            </Route>
                            <Route path="/login" exact>
                                <Login />
                            </Route>
                            <Route path="/shopify/callback">
                                <OauthLanding />
                            </Route>
                            <Route path="/signup" component={Signup} exact />
                        </> :
                        <>
                        <ChatWidget
        // `accountId` is used instead of `token` in older versions
        // of the @papercups-io/chat-widget package (before v1.2.x).
        // You can delete this line if you are on the latest version.
        // accountId="527ead17-dddd-42f6-a2b9-133bd33ed3c3"
        token="527ead17-dddd-42f6-a2b9-133bd33ed3c3"
        inbox="15edad34-b354-4882-96fd-8ac9d7666897"
        title="Welcome to Shiftsy"
        subtitle="Ask us anything in the chat window below 😊"
        primaryColor="#000000"
        newMessagePlaceholder="Start typing..."
        showAgentAvailability={false}
        agentAvailableText="We're online right now!"
        agentUnavailableText="We're away at the moment."
        requireEmailUpfront={false}
        iconVariant="outlined"
        baseUrl="https://app.papercups.io"
        // Optionally include data about your customer here to identify them
        // customer={{
        //   name: __CUSTOMER__.name,
        //   email: __CUSTOMER__.email,
        //   external_id: __CUSTOMER__.id,
        //   metadata: {
        //     plan: "premium"
        //   }
        // }}
      />

                        <OnboardingSequence onboarded={user.onboarded}/>
                            <Route path="/login" exact>
                                <Redirect to="/" />
                            </Route>
                            <Route path="/shopify/callback">
                                <Redirect to="/" />
                            </Route>

                            <Row>
                                <Col className="sidebar-column">
                                    <AppSidebar />
                                </Col>
                                <Col>
                                    <Container className="dashboard-content">
                                        <Route path="/" exact>
                                            <Redirect to="/dashboard" />
                                        </Route>
                                        <Route path="/dashboard" exact>
                                            <DashboardPage />
                                        </Route>
                                        <Route path="/settings" exact>
                                            <SettingsPage />
                                        </Route>
                                        <Route path="/account" exact>
                                            <Pricing />
                                        </Route>
                                    </Container>
                                </Col>
                            </Row>
                        </>
                    }
                </Router >
            </JWTContext.Provider>
        </div>
    )
}

export default App;
