import logo from '../logo.svg'
import './Login.css';

import { useContext } from 'react';
import Button from 'react-bootstrap/Button'
import { Form, Card, Alert, FloatingLabel } from 'react-bootstrap'
import {
    useLocation,
} from "react-router-dom";
import { JWTContext } from '../Contexts'
import { gql, useMutation, OperationVariables, FetchResult, ApolloError } from '@apollo/client'
import qs from 'qs'
import { useState } from 'react'

const LOGIN_QUERY = gql`
    mutation Login($email: String!, $password: String!) {
        loginUser(email: $email, password: $password) {
            token
        }
    }
`;

// function withLoginQuery(Component: typeof React.Component): React.FunctionComponent<any> {
//     return function WrappedComponent(props: React.Component<any, any>) {
//         const [
//             loginQuery,
//             result
//         ] = useMutation(LOGIN_QUERY);
//         return <Component {...props} query={loginQuery} result={result} />
//     }
// }

const Login = () => {
    let location = useLocation()

    const next = qs.parse(location.search, { ignoreQueryPrefix: true }).next?.toString()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loginQuery, result] = useMutation(LOGIN_QUERY)
    const ctx = useContext(JWTContext)

    const handleSubmitButtonClick = () => {
        loginQuery({
            variables: {
                email,
                password,
            }
        }).then((res: FetchResult) => {
            ctx.setJwt(res.data!.loginUser.token)
        }).catch((err: ApolloError) => {
            console.warn("Error logging in", err.message)
        })
    }

    const create_form = (
        <>
            <Form.Group className="w-100">
                {/* <FloatingLabel label="Email" controlId="email">
                    <Form.Control
                        type="text"
                        placeholder="email"
                        className="loginInput mb-3"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FloatingLabel>

                <FloatingLabel label="Password" controlId="password">
                    <Form.Control
                        placeholder="Password"
                        type="password"
                        className="loginInput mb-3"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FloatingLabel> */}


                {/* {
                    result.error ?
                        <div className="text-danger mb-3">
                            {result.error.message.charAt(0).toUpperCase() +
                                result.error.message.slice(1)}
                        </div> :
                        <></>
                }
                <Button onClick={handleSubmitButtonClick} style={{ 'width': '100%' }} className="mb-3">
                    {!result.loading ?
                        <> Log In </>
                        : <> Loading </>
                    }
                </Button>

                <hr /> */}
                <Button variant="outline-success" className="mb-3"
                    onClick={() =>
                        window.location.href = "https://apps.shopify.com/monoid-2"
                    }
                >
                    Sign in with Shopify
                </Button>
                <div className="mb-3">
                    Don't have an account?
                    <a href="/signup" className="no-underline ms-2">Sign Up</a>
                </div>
                { /* <div className="w-100 d-flex justify-content-center">
                    <Button onClick={() => this.props.history.push(
                        next ? "/signup?next=" + next : "/signup"
                    )} variant="link"> Sign Up </Button>
                    </div> */ }
            </Form.Group>
        </>
    )

    return <>
        <div className="login-region full-height">
            <div className="login-bk-top" />
            <div className="login-bk-bot" />
            <Card className={"shadow-card login-card " + (false ? "error-card" : "")}>
                <Card.Body className="pb-0">
                    <img src={logo} className="login-image" alt={"Shiftsy Logo"} />
                    <h4 className="mt-3 mb-3"> Sign in to Shiftsy </h4>
                    {create_form}
                </Card.Body>
            </Card>
        </div>
    </>
}

export default Login;
