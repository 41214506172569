import { gql, useMutation, useQuery } from '@apollo/client'
import { useEffect, useState, useRef } from 'react';
import { default as dayjs } from 'dayjs';
import { default as duration } from 'dayjs/plugin/duration'
import { default as relativeTime } from 'dayjs/plugin/relativeTime'
import { createUseStyles } from 'react-jss'
import { Transition } from 'react-transition-group';
import { create } from "jss";
import { JssProvider } from 'react-jss'
import preset from 'jss-preset-default'
import Frame, { FrameContextConsumer } from 'react-frame-component'
import { Settings, SettingsLocation } from './lib/models';
import { GET_SETTINGS } from './Settings'

function usePrevious(value: any, shouldUpdate: (prev: any, next: any) => boolean) {
    const ref = useRef<any>();
    useEffect(() => {
        if (shouldUpdate(ref.current, value)) {
            ref.current = value;
        }
    });
    return ref.current;
}

dayjs.extend(duration)
dayjs.extend(relativeTime)

const SHOW_POPUP_QUERY = gql`
    mutation ShowPopup($eventBlacklist: [Int!], $shopName: String!) {
        showPopup(eventBlacklist: $eventBlacklist, shopName: $shopName) {
            eventId
            popupId
            title
            subtitle
            time
            link
            imageSrc
        }
    }
`;

const RECORD_EVENT = gql`
    mutation RecordEvent($input: RecordPopupEventInput!) {
        recordPopupEvent(input: $input)
    }
`

const useStyles = createUseStyles({
    '@global': {
        body: {
            fontFamily: "Roboto",
        },
        '*, *::before, *::after': {
            boxSizing: 'border-box'
        },
    },
    dFlex: {
        display: "flex",
    },
    popup: {
        display: "flex",
        alignItems: "center",
        width: "350px",
        height: "100px",
        borderRadius: "150px",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
        paddingLeft: "15px",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingRight: "40px",
        overflow: "hidden",
        cursor: "pointer",
        backgroundColor: "white",
        transition: "box-shadow 0.1s",
        '&:hover': {
            boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.2)",
        },
        '& img': {
            height: "60px",
            width: "60px",
            borderRadius: "100%",
            border: "1px solid #ababab",
        },
        '& b': {
            fontSize: '15px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            marginBottom: "4px",
        },
    },
    subtitle: {
        fontWeight: 700,
        fontSize: '13px',
        color: 'rgba(150, 150, 150, 1.0)',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginBottom: '10px',
        marginTop: 0,
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "1rem",
        width: "100%",
        minWidth: "0",
        overflow: "hidden"
    },
    timeText: {
        fontSize: '11px',
        color: 'rgba(150, 150, 150, 1.0)'
    },
    verifiedText: {
        fontSize: '11px',
        color: 'green',
        marginLeft: 'auto',
        fontWeight: 600,
    },
    popupInitial: {
        transition: 'opacity 100ms ease-in'
    },
})

const initialContent = (settingsLocation: SettingsLocation) => {
    let location = ""
    switch (settingsLocation) {
        case SettingsLocation.BOTTOM_LEFT:
            location = "left: 0; bottom: 0;"
            break;
        case SettingsLocation.BOTTOM_RIGHT:
            location = "right: 0; bottom: 0;"
            break;
        case SettingsLocation.TOP_LEFT:
            location = "left: 0; top: 0;"
            break;
        case SettingsLocation.TOP_RIGHT:
            location = "right: 0; top: 0;"
            break;
    }
    return (
        `<!DOCTYPE html>
        <html style="position: absolute; ${location}">
            <head>
            <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
          />
            </head>
            <body style="background-color: transparent;">
                <div id="page" class="page"></div>
            </body>
        </html>`
    )
}

export function PopupSettingsInjectedFrame(props: { scriptSrc: string }) {
    const url = new URL(props.scriptSrc)
    let shop = url.searchParams.get('shop')

    if (shop == "" || !shop) {
        shop = "shiftsy-test-store.myshopify.com"
    }

    const { loading, error, data } = useQuery(GET_SETTINGS, {
        variables: {
            shopName: shop
        }
    })

    if (loading || error) {
        return <></>
    }

    return <PopupFrame
        settingPage={false}
        settings={data.settings}
        shopName={shop}
    />
}

function PopupFrame(props: {
    settingPage: boolean,
    popupData?: PopupData,
    settings: Settings,
    shopName?: string
}) {
    const [frameHidden, setFrameHidden] = useState(true)
    let style: any = {
        height: '0px', width: '0px', border: 'none', zIndex: "10000"
    }

    if (!props.settings.enabled) {
        return <></>
    }

    if (!frameHidden || props.settingPage) {
        style = {
            ...style,
            height: '150px',
            width: '400px',
            position: 'fixed',
        }
    }

    switch (props.settings.location) {
        case SettingsLocation.BOTTOM_LEFT:
            style.bottom = '0'
            style.left = '0'
            break;
        case SettingsLocation.BOTTOM_RIGHT:
            style.bottom = '0'
            style.right = '0'
            break;
        case SettingsLocation.TOP_LEFT:
            style.top = '0'
            style.left = '0'
            break;
        case SettingsLocation.TOP_RIGHT:
            style.top = '0'
            style.right = '0'
            break;
    }

    if (props.settingPage) {
        style = {
            ...style,
            position: 'absolute',
        }
    }

    return (
        <Frame initialContent={initialContent(props.settings.location)} style={style} >
            <FrameContextConsumer>
                {(v: { document: any }) => {
                    const jss = create({
                        ...preset(),
                        insertionPoint: v.document.head
                    });


                    return (
                        <JssProvider jss={jss}>
                            {(!props.settingPage) ?
                                <FullPopupComponent
                                    setFrameHidden={setFrameHidden}
                                    settings={props.settings}
                                    shopName={props.shopName!}
                                /> :
                                <PopupElement popupData={props.popupData!} settingsPage />
                            }
                        </JssProvider>
                    )
                }}
            </FrameContextConsumer>
        </Frame>
    )
}

export type PopupData = {
    imageSrc: string,
    subtitle: string,
    popupId: number,
    link: string,
    title: string,
    time: Date
}

export function PopupElement({ popupData, settingsPage }: {
    popupData: PopupData
    settingsPage?: boolean
}) {
    let classes = useStyles()
    let lstr = dayjs.duration(dayjs(popupData.time).diff(dayjs())).humanize(true)
    const [recordEvent, recordEventResult] = useMutation(RECORD_EVENT)

    return (
        <div className={classes.popup} onClick={() => {
            if (settingsPage) {
                return
            }

            recordEvent({
                variables: {
                    input: {
                        clicked: true,
                        popupId: popupData.popupId,
                    }
                }
            })
            window.open(popupData.link, "_blank")
        }}>
            <img src={popupData.imageSrc} />
            <div className={classes.textContainer}>
                <b> {popupData.title} </b>
                <p className={classes.subtitle}> {popupData.subtitle} </p>
                <div className={classes.dFlex}>
                    <div className={classes.timeText}>
                        {lstr}
                    </div>
                    <div className={classes.verifiedText}> Verified by Shiftsy </div>
                </div>
            </div>
        </div>
    )
}

function FullPopupComponent(props: {
    setFrameHidden: (hidden: boolean) => void,
    settings: Settings,
    shopName: string,
}) {
    const classes = useStyles()

    const [popupQuery, result] = useMutation(SHOW_POPUP_QUERY)

    const previousData = usePrevious(
        result.data,
        (p, next) => (next && next.showPopup) && (!p || !p.showPopup || next.showPopup.popupId !== p.showPopup.popupId)
    );

    const [pIn, setIn] = useState(false)
    const [showPrev, setShowPrev] = useState<number | undefined>(undefined)
    console.log(previousData, result.data, showPrev)

    const runPopupQuery = (eventBlacklist: number[]) => {
        let lastUpdated = localStorage.getItem("shiftsy_last_updated")
        let count = 0

        if (lastUpdated && lastUpdated != "") {
            let lastUpdatedTime = new Date(lastUpdated)
            if (lastUpdatedTime.getDay() == (new Date()).getDay()) {
                let countStr = localStorage.getItem("shiftsy_view_count")

                count = parseInt(countStr || "0")
            }
        }

        console.log("Amount", props.settings.amount, count)

        if (count >= props.settings.amount) {
            setIn(false)
            setTimeout(() => {
                props.setFrameHidden(true)
            }, 1000)
            return
        }

        if (result.data && result.data.showPopup) {
            setShowPrev(result.data.showPopup.popupId)
        }

        setIn(false)
        popupQuery({
            variables: {
                eventBlacklist: eventBlacklist,
                shopName: props.shopName
            }
        }).then((res) => {
            let newBlacklist = eventBlacklist

            if (res.data && res.data.showPopup) {
                localStorage.setItem("shiftsy_last_updated", (new Date()).toUTCString())
                localStorage.setItem("shiftsy_view_count", (count + 1).toString())

                newBlacklist = [...newBlacklist, res.data.showPopup.eventId]
                localStorage.setItem("shiftsy_event_blacklist", JSON.stringify(newBlacklist))

                props.setFrameHidden(false)
                setIn(false)
                setTimeout(() => {
                    setShowPrev(undefined)
                    setIn(true)
                }, 2000)
            } else {
                setIn(false)
                setTimeout(() => {
                    props.setFrameHidden(true)
                }, 1000)
            }

            setTimeout(() => {
                runPopupQuery(newBlacklist)
            }, 2000 + 1000 * props.settings.delay)
        })
    }

    useEffect(() => {
        let blacklistStr = localStorage.getItem("shiftsy_event_blacklist")
        let lastUpdated = localStorage.getItem("shiftsy_last_updated")
        let blacklist = []

        if (lastUpdated && lastUpdated != "") {
            let lastUpdatedTime = new Date(lastUpdated)
            if (lastUpdatedTime.getDay() == (new Date()).getDay()) {
                if (blacklistStr && blacklistStr != "") {
                    blacklist = JSON.parse(blacklistStr)
                }
            }
        }

        runPopupQuery(blacklist)
    }, [])

    let popupData: any = undefined
    if (!result.data || !result.data.showPopup) {
        if (previousData) {
            popupData = previousData.showPopup
        }
    } else {
        popupData = result.data.showPopup
    }

    if (!popupData) {
        return <></>
    }

    const transitionStyles = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
        unmounted: {},
    };

    // let lstr = dayjs.duration(1000).humanize()
    return (
        <Transition
            in={pIn}
            addEndListener={() => { }}
        >
            {state => (
                <div className={classes.popupInitial} style={{
                    ...transitionStyles[state]
                }}>
                    <PopupElement
                        popupData={popupData}
                    />
                </div>
            )}
        </Transition>
    )
}

export default PopupFrame