function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function ErrorPage(props: { errorTitle: string, errorText: string, centered?: boolean }) {
    return (
        <div className={"d-flex flex-column " + (props.centered ? "align-items-center" : "")} >
            <h2> {props.errorTitle} </h2>
            <p> {capitalize(props.errorText)} </p>
        </ div>
    )
}