import React from 'react';
import { User } from './lib/models'

interface ContextType {
    user?: User
    jwt: string
    setJwt: (jwt: string) => void
    updateUser: any
}

const initVal: ContextType = {
    setJwt: (__a: string) => {
        throw new Error('Unimplemented default');
    },
    jwt: '',
    user: undefined,
    updateUser: (u: any) => {
        throw new Error('Unimplemented default');
    }
}
export const JWTContext = React.createContext(initVal);
