import { gql, useQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Settings, SettingsLocation } from './lib/models';
import { Form, Button, Card, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import PopupFrame from './PopupComponent';
import logo from './logo.svg'

export const GET_SETTINGS = gql`
    query GetSettings($shopName: String) {
        settings(shopName: $shopName) {
            addToCart
            purchase
            location
            amount
            delay
            enabled
        }
      }
`;


const UPDATE_SETTINGS = gql`
    mutation UpdateSettings($input: SettingInput!) {
        updateSettings(input: $input) 
    }
`;

function SettingsPage() {
    let [current_settings, setCurrentSettings] = useState<Settings>({
        addToCart: false,
        purchase: false,
        location: SettingsLocation.BOTTOM_LEFT,
        amount: 0,
        delay: 0,
        enabled: false,
    });

    const [settingsMutation, { data: data_settings, loading: loading_settings, error: error_settings }] = useMutation(UPDATE_SETTINGS)

    const { loading, error, data } = useQuery(GET_SETTINGS, {
        onCompleted: (data) => {
            setCurrentSettings({
                addToCart: data.settings.addToCart,
                purchase: data.settings.purchase,
                location: data.settings.location as SettingsLocation,
                amount: data.settings.amount,
                delay: data.settings.delay,
                enabled: data.settings.enabled,
            })
        }
    });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error :( </div>;



    let EventTypeComponent = () => {
        return (<Form.Group
            className="mb-3"
            controlId="eventType">
            <Form.Check
                checked={current_settings.addToCart}
                type="checkbox"
                label="Adds to Cart"
                onChange={(e) => {
                    let new_settings = { ...current_settings }
                    new_settings.addToCart = e.target.checked
                    setCurrentSettings(new_settings)
                }}
                id={"cart"}
            />

            <Form.Check
                checked={current_settings.purchase}
                type="checkbox"
                label="Purchases"
                onChange={(e) => {
                    let new_settings = { ...current_settings }
                    new_settings.purchase = e.target.checked
                    setCurrentSettings(new_settings)
                }}
                id={"purchases"}
            />
            <small>
                Popups can be triggered either when a customer adds an item to their cart,
                or when they check out, or both.
            </small>
        </Form.Group>
        )
    }

    let EnabledButton = () => {
        return (
            <>
                <Form.Check
                    type="switch"
                    onChange={() => {
                        let new_settings = { ...current_settings }
                        new_settings.enabled = !current_settings.enabled
                        setCurrentSettings(new_settings)
                    }}
                    checked={current_settings.enabled}
                />
                <small> Popups will only start showing once you enable this setting. </small>
            </>
        )
    }

    let handleLocationChange = (e: any): void => {
        let new_settings = { ...current_settings }
        new_settings.location = e.target.value as SettingsLocation;
        setCurrentSettings(new_settings)
    }


    let LocationComponent = () => {
        return <Form.Group className="mb-3" controlId="location">
            <Form.Check
                onChange={handleLocationChange}
                value={SettingsLocation.BOTTOM_LEFT}
                checked={current_settings.location == SettingsLocation.BOTTOM_LEFT}
                type="radio"
                name="group1"
                label="Bottom Left"
                id="bl"
            />
            <Form.Check
                onChange={handleLocationChange}
                value={SettingsLocation.BOTTOM_RIGHT}
                checked={current_settings.location == SettingsLocation.BOTTOM_RIGHT}
                type="radio"
                name="group1"
                label="Bottom Right"
                id="br"
            />
            <Form.Check
                onChange={handleLocationChange}
                value={SettingsLocation.TOP_LEFT}
                checked={current_settings.location == SettingsLocation.TOP_LEFT}
                type="radio"
                name="group1"
                label="Top Left"
                id="tl"
            />
            <Form.Check
                onChange={handleLocationChange}
                value={SettingsLocation.TOP_RIGHT}
                checked={current_settings.location == SettingsLocation.TOP_RIGHT}
                type="radio"
                name="group1"
                label="Top Right"
                id="tr"
            />
        </Form.Group>
    }

    let AmountComponent = () => {
        return <>
            <Form.Control
                type="number"
                value={current_settings.amount}
                onChange={(e) => {
                    let new_settings = { ...current_settings }
                    new_settings.amount = Math.max(parseInt(e.target.value), 1)
                    setCurrentSettings(new_settings)
                }}
            />
            <small className="mt-2 d-flex"> Shiftsy popups will show at most this many times per day, per user </small>
        </>
    }

    let DelayComponent = () => {
        return <>
            <InputGroup>
                <Form.Control
                    type="number"
                    value={current_settings.delay}
                    onChange={(e) => {
                        let new_settings = { ...current_settings }
                        new_settings.delay = Math.max(parseInt(e.target.value), 1)
                        setCurrentSettings(new_settings)
                    }} />
                <InputGroup.Text>seconds</InputGroup.Text>
            </InputGroup>
        </>
    }

    return <>
        <div className="d-flex">
            <h2>Settings</h2>
            <Button
                className="ms-auto"
                onClick={() => {
                    settingsMutation({
                        variables: {
                            input: current_settings
                        }
                    })
                }}
                variant="primary"
                disabled={loading_settings}
            >
                {loading_settings ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : "Save"}
            </Button>

        </div>
        <div className="d-flex mt-3">
            <Card className="settings-card">
                <Card.Body>
                    <div className="setting-group">
                        <b> Visibility </b>
                        <EnabledButton />
                    </div>
                    <div className="setting-group">
                        <b> Event Types </b>
                        <EventTypeComponent />
                    </div>
                    <div className="setting-group">
                        <b> Location </b>
                        <LocationComponent />
                    </div>
                    <div className="setting-group">
                        <b> Frequency </b>
                        <AmountComponent />
                    </div>
                    <div className="setting-group">
                        <b> Timing </b>
                        <DelayComponent />
                    </div>
                </Card.Body>
            </Card>
            <Card className="web-embed-card">
                <div className="web-embed-body">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar w-75"></div>
                    <div className="bar-spacer"></div>
                    <div className="bar"></div>
                    <div className="bar w-75"></div>
                </div>
                <PopupFrame
                    popupData={{
                        title: "Richard from San Jose, CA",
                        subtitle: "recently purchased Pied Piper Pro!",
                        link: "",
                        popupId: -1,
                        time: new Date(),
                        imageSrc: logo,
                    }}
                    settings={current_settings}
                    settingPage
                />
            </Card>

        </div>
    </>
}

export default SettingsPage