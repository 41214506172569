import React from 'react';
import { JWTContext } from '../Contexts'
import { withRouter } from 'react-router-dom'
import ErrorPage from '../ErrorPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

class OauthLanding extends React.Component<any, any> {
    static contextType = JWTContext

    constructor(props: any) {
        super(props)
        this.state = {
            error: null
        }
    }

    componentDidMount() {
        let queryUrl = (`${process.env.REACT_APP_API_URL}/api/shopify/callback` +
            this.props.location.search)

        console.log("API", process.env, queryUrl)

        fetch(queryUrl, {
            method: 'GET',
            redirect: 'manual',
            mode: 'cors',
            credentials: 'include',
        }).then(res => res.json()).then(res => {
            console.log("Test", res)
            if (res.error) {
                console.log("ERROR", res.error)
            } else {
                this.context.setJwt(res.token)
            }


            // let oauthRedir = localStorage.getItem('oauth_redirect')

            // if (oauthRedir) {
            //     localStorage.removeItem('oauth_redirect')
            //     this.props.history.push(oauthRedir)
            // } else {
            //     this.props.history.push(`/teams/${teamId}/integrations`)
            // }
        })
    }

    render() {
        if (this.state.error) {
            return <ErrorPage errorTitle={"Error"} errorText={this.state.error} />
        }

        return (
            <>
                <FontAwesomeIcon icon={faSpinnerThird} spin />
            </>
        )
    }
}

export default withRouter(OauthLanding)